<template>
  <div class="DCIDetail">
    <div>
      <span class="back" @click="back">
        <i class="el-icon-arrow-left"></i> 返回
      </span>
    </div>
    <div class="searchBar">
      <div class="tittle"> 我的评价 <i class="icon iconfont">&#xe677;</i> 评价详情</div>
    </div>
    <div class="detailTitle">{{orderInfo.workName}} 
      <!-- <span>申领成功</span> -->
      <span class="stausCell" v-if="orderInfo.status"
            :class="{'stausCell1':orderInfo.status=='31',
            'stausCell2':orderInfo.status=='40',
            'stausCell3':orderInfo.status=='11'|| orderInfo.status=='13' || orderInfo.status=='13' || orderInfo.status=='20',
            'stausCell4':orderInfo.status=='10',
            'stausCell5':orderInfo.status=='21',}">{{orderInfo.statusText}}</span>
    </div>
    <p class="detailTitleDCI" v-if="orderInfo.dciCode"><i class="icon iconfont">&#xe605;</i> <span>{{orderInfo.dciCode}}</span> <i class="icon iconfont"></i></p>

    <div class="infoBox">
      <div class="title">作品信息</div>
      <div class="infoList">
        <!-- <div><span>作品类别</span><p>文字作品</p></div>
        <div><span>首次发表日期</span><p v-if="orderInfo.publicationDate">{{orderInfo.publicationDate}}</p></div>
        <div><span>作品文件</span><p class="infoFile" v-if="orderInfo.items"><i class="icon iconfont">&#xe67b;</i> {{orderInfo.items[0].sourceName}}.{{orderInfo.items[0].fileType}}</p></div>
        <div><span>权利范围</span><p>全部</p></div>
        <div><span>创作性质</span><p>原创</p></div>
        <div><span>权利取得方式</span><p>原始</p></div>
        <div><span>发表状态</span><p>已发表</p></div>
        <div><span>创作完成日期</span><p v-if="orderInfo.creationCompletionDate">{{orderInfo.creationCompletionDate}}</p></div>
        <div><span>权利归属方式</span><p>个人作品</p></div>
        <div class="infoListBox2" v-if="orderInfo.status=='31'"><span>申领失败原因</span><p>{{orderInfo.remark}}</p></div> -->


          <div><p>登记号</p><span class="registerNo" v-if="orderInfo.registerCode">{{orderInfo.registerCode}}</span></div>
          <div><p>作品名称</p><span>{{orderInfo.workName}}</span></div>

          <div><p>作者</p><span v-for="(item,index) in orderInfo.author" :key="index">{{item}}</span></div>
          <div><p>著作权人</p><span v-if="orderInfo.certificateInfo">{{orderInfo.certificateInfo.body.data.obligee}}</span><span v-else>{{obligeeDetail.userName}}</span></div>

          <div><p>发表状态</p><span v-if="orderInfo.publicationStatus">{{ constants.publishStatus[orderInfo.publicationStatus * 1-1].label }}</span></div>
          <div><p>发表平台</p><span v-if="orderInfo.publicationPlatform">{{ orderInfo.publicationPlatform }}</span></div>

          <div><p>创作性质</p><span v-if="orderInfo.creationNature">{{ constants.creationNature[orderInfo.creationNature * 1-1].label }}</span></div>
          <div><p>作品类型</p><span v-if="orderInfo.workCategory">{{ constants.worksType[orderInfo.workCategory * 1-1].label }}</span></div>

          <div><p>创作完成日期</p><span v-if="orderInfo.creationCompletionDate">{{ orderInfo.creationCompletionDate }}</span></div>
          <div><p>首次发表日期</p><span v-if="orderInfo.publicationDate">{{ orderInfo.publicationDate }}</span></div>

          <div><p>作品文件</p>
              <!-- 如果是图片格式，点击可放大 -->
              <span class="file" v-if="orderInfo.items && orderInfo.items[0].fileType == 'png' || orderInfo.items[0].fileType == 'jpg'"  @click="previewImg(orderInfo.items[0].downloadUrl)"><i class="icon iconfont">&#xe67b;</i> {{orderInfo.items[0].sourceName}}.{{orderInfo.items[0].fileType}}</span>
              <span class="file" v-else><i class="icon iconfont">&#xe67b;</i> {{orderInfo.items[0].sourceName}}.{{orderInfo.items[0].fileType}}</span>
          </div>
        <div class="infoListBox2" v-if="orderInfo.status=='31'"><span>失败原因</span><p>{{orderInfo.remark}}</p></div>
      </div>
    </div>

    <div class="infoBox">
      <div class="title">评价信息</div>
      <div class="infoList">
        <!-- <div><span>申领人</span><p>{{orderInfo.authorName}}</p></div>
        <div><span>提交申领时间</span><p>{{orderInfo.createTimeText}}</p></div>
        <div><span>更新时间</span><p>{{orderInfo.updateTimeText}}</p></div> -->
      </div>
    </div>

    <div class="infoBox infoBox2" v-if="orderInfo.status=='21'">
      <div class="title">证书信息</div>
      <div class="infoList">
        <div><span>证书</span><div @click="previewImg(orderInfo.certificateInfo.jpgUrl)"><img :src="orderInfo.certificateInfo.jpgUrl" alt=""><div><img src="../../../assets/images/search.png" alt=""></div></div></div>
      </div>
    </div>



    <!-- 图片预览 -->
      <el-image-viewer
        v-if="showImageViewer"
        :url-list="[showImageUrl]"
        hide-on-click-modal
        teleported
        :on-close="closePre"
      />

  </div>
</template>

<script>
import constants from "@/utils/constants";
import { queryEvaluationDetail } from "@/api/EvidenceService"
import { queryObligeeDetail } from "@/api/ObligeeService";
export default {
  components: {'el-image-viewer': () => import('element-ui/packages/image/src/image-viewer'),},
  data () {
    return {
      constants,
      orderNo: this.$route.query.orderNo,
      obligeeDetail: {},
      orderInfo: {certificateInfo:{body:{data:{}}},items:[{}]},
      showImageViewer: false,
      showImageUrl: '',
    }
  },
  created() {
    // 获取DCI订单详细信息
    queryEvaluationDetail(this.$route.query.orderNo).then(({ data }) => {
      // console.log('获取DCI订单详细信息------',data);
        this.orderInfo = data
        this.orderInfo.statusText=this.getStatus(data.status);

        // 获取相关 权利人/作者，以及权利人编码
        queryObligeeDetail(data.copyrightOwnerCodes[0]).then(({ data }) => {
            console.log('获取权利人相关信息',data);
            this.obligeeDetail = data;
        });
    });
  },
  methods: {
    back() {
      this.$router.go(-1); //返回上一层
    },
    getStatus(code){
        switch(code){
          case '10':return '待付款';
          case '11':return '待处理';
          case '12':return '检测中';
          case '13':return '申领中';
          case '20':return '待处理';
          case '21':return '申领成功';
          case '31':return '申领失败';
          case '40':return '已取消';
        }
    },

    previewImg(url){
      document.body.style.overflow = 'hidden';
      this.showImageUrl = url;
      this.showImageViewer = true;
    },
    closePre(){
      document.body.style.overflow = 'auto';
      this.showImageUrl = '';
      this.showImageViewer = false;
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../assets/css/mine.scss';
.DCIDetail {
    padding: 18px 24px;
    .searchBar{
      height: 40px;
      .title{
        height: 40px;
        line-height: 40px;
      }
      .icon{
        font-weight: bold;
      }
    }
    .detailTitle{
      display: flex;
      align-items: center;
      font-size: 24px;
      font-family: 'PingFangSC, PingFang SC';
      font-weight: 500;
      color: rgba(0,18,22,0.85);
      line-height: 50px;
      span{margin-left: 12px;display: block;background: #E0FAEA;border-radius: 4px;border: 1px solid #BCEFD2;height: 22px;line-height: 22px;padding: 0 8px;font-size: 12px;color: #007F56;}
      span.stausCell1{
          color: #E93030;
          border-color: #E93030;
          background: #FAE5E0;
      }
      span.stausCell2{
          color: #A6A6A6;
          border-color: #A6A6A6;
          background:#F5F5F5;
      }
      span.stausCell3{
          color: #0063DA;
          border-color: #0063DA;
          background:#E0EFFA;
      }
      span.stausCell4{
          color: #DB5A00;
          border-color: #DB5A00;
          background:#FDEED7;
      }
      span.stausCell5{
          color: #007F56;
          border-color: #007F56;
          background: #E0FAEA;
      }
    }
    .detailTitleDCI{
      display: flex;
      align-items: center;
      span{
        font-size: 16px;
        font-family: 'PingFangSC, PingFang SC';
        margin: 0 10px;
      }
      i:nth-child(1){
        color: #007DFF;
        font-size: 24px;
      }
    }

    .infoBox{
      padding: 20px 30px;
      background: #FFFFFF;
      box-shadow: 0px 0px 7px 0px rgba(0,70,144,0.09);
      margin: 20px 0;
      .title{
        font-size: 20px;
        font-family: 'PingFangSC, PingFang SC';
        font-weight: 500;
        color: rgba(0,18,22,0.85);
        line-height: 36px;
        position: relative;
        margin-bottom: 10px;
      }
      .title::before{content: '';width: 4px;height: 20px;border-radius: 2px;background: #027DFF;position: absolute;top: 8px;left: -12px;}
      .infoList{
        display: flex;
        flex-wrap: wrap;
	      justify-content: flex-start;
        div{
          width: 50%;
          display: flex;
          align-content: center;
          height: 40px;
          p{display: block;width: 130px;line-height: 40px;font-size: 14px;font-family: 'PingFangSC, PingFang SC';font-weight: 500;color: rgba(0,18,22,0.45);white-space: nowrap;max-width: 100%;overflow: hidden;text-overflow: ellipsis;}
          span{width: calc(100% - 130px);line-height: 40px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;font-size: 14px;font-family: 'PingFangSC, PingFang SC';color: #001216;padding-right: 20px;}
          span.registerNo{display: inline-block;width: auto;height: 30px;line-height: 30px;padding: 0 10px;background: #EAF3FF;color: #104189;border-radius: 4px;margin: 5px 0;}
          span.file{color: #316EF4;cursor: pointer;}
          .infoFile{color: #316EF4;}
        }
        .infoListBox2{width: 100%;
          span{color: #E4403C;opacity: 1;}
        }
      }
      .infoCertificate{
        p{font-size: 14px;font-family: 'PingFangSC, PingFang SC';font-weight: 500;color: rgba(0,18,22,0.65);line-height: 24px;margin-bottom: 10px;margin-top: 50px;}
        span{font-size: 12px;font-family: 'PingFangSC, PingFang SC';font-weight: 400;color: rgba(0,18,22,0.5);line-height: 20px;}
        button{display: block;padding: 0 10px;margin-top: 50px;height: 34px;line-height: 32px;background: #316EF4;color: #FFFFFF;border-radius: 4px;font-size: 14px;font-family: 'SourceHanSansCN, SourceHanSansCN';
          font-weight: 500;border: none;outline: none;cursor: pointer;}
        img{width: 100%;}
      }
    }

    .infoBox2 .infoList div{height: 80px!important;}
    .infoBox2 .infoList div div{width: auto;cursor: pointer;position: relative;}
    .infoBox2 .infoList div div div{width: 100%;height: 100%;background: rgba(0,0,0,0.2);position: absolute;left: 0;top: 0;display: none;}
    .infoBox2 .infoList div div div img{width: 30px;height: 30px;margin: 25px auto;display: block;}
    .infoBox2 .infoList div div:hover div{display: block;}
}

// 控制放大证书的大小，全屏看起来太大
::v-deep .el-image-viewer__img{max-width: 70% !important;height: auto !important;}


@media screen and (max-width: 991px){
  .DCIDetail .infoBox .infoList div{width: 100%;}
  .DCIDetail .detailTitle{display: block;}
  .DCIDetail .detailTitle span{white-space: nowrap;display: inline-block;}
}

</style>
